export default {
  name: 'PromoMessage',
  props: {
    order: {
      required: true,
      type: Object
    },
    currency: {
      required: true,
      type: Object
    }
  },
  methods: {
    getPriceWithVat (price) {
      return this.$parent.getPriceWithVat(price)
    },
    reverseRouteName (str, defaultName = 'route-name') {
      return this.$parent.reverseRouteName(str, defaultName)
    }
  }
}
